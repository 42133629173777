import * as sequences from "./sequences";
// import * as reactions from "./reactions";

export const state = {
  // token: ""
  uploadedPhoneNumbersFileName: "",
  uploadedPhoneNumbersFileSize: 0
};

export default {
  // reactions,
  state,
  sequences: {
    ...sequences
  }
};
