import React from "react";
import {
  Page,
  BlockTitle,
  Block,
  List,
  ListItem,
  Link,
  Icon,
  ListButton
} from "framework7-react";
import { connect } from "@cerebral/react";
import { sequences, state } from "cerebral";

export default connect(
  {
    foo: state`foo`,
    onClick: sequences`onClick`,
    page: state`page`,
    selectedUsers: state`selectedUsers`,
    clearUsers: sequences`marketting.clearUsers`
  },
  ({ clearUsers, selectedUsers }) => {
    const activeLink = "active-link";
    return (
      <Page>
        <List>
          <ListButton onClick={() => clearUsers()} title="Clear" />
        </List>
        <List>
          {Object.keys(selectedUsers).map(user => {
            const {
              id,
              phone_number,
              user_id,
              created_at,
              display_name
            } = selectedUsers[user];

            return (
              <ListItem
                onClick={() => {}}
                title={`${phone_number} | ${user_id}`}
                link="#"
              />
            );
          })}
        </List>
      </Page>
    );
  }
);
