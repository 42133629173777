import React from "react";
import {
  Page,
  LoginScreenTitle,
  List,
  ListInput,
  ListButton,
  BlockFooter
} from "framework7-react";
import { connect } from "@cerebral/react";
import { sequences, state } from "cerebral";

export default connect(
  {
    companyName: state`config.companyName`,
    loggedIn: state`loggedIn`,
    foo: state`foo`,
    onClick: sequences`onClick`,
    signIn: sequences`app.signIn`
  },
  class MyComponent extends React.Component {
    constructor() {
      super();
      this.state = {
        username: "",
        password: "",
        pigeons: []
      };
    }
    render() {
      const { companyName } = this.props;
      return (
        <Page noToolbar noNavbar noSwipeback loginScreen>
          <LoginScreenTitle>{companyName}</LoginScreenTitle>
          <List form>
            <ListInput
              label="Email"
              type="email"
              placeholder="Your Email"
              value={this.state.username}
              onInput={e => {
                this.setState({ username: e.target.value });
              }}
            />
            <ListInput
              label="One Time Password"
              type="password"
              placeholder="Your password"
              value={this.state.password}
              onInput={e => {
                this.setState({ password: e.target.value });
              }}
            />
          </List>
          <List>
            <ListButton onClick={this.signIn.bind(this)}>Sign In</ListButton>
            <BlockFooter>
              Some text about login information.
              <br />
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </BlockFooter>
          </List>
        </Page>
      );
    }
    signIn() {
      this.props.signIn({ ...this.state });
    }
  }
);
