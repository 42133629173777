import React from "react";
import {
  Page,
  LoginScreenTitle,
  List,
  ListInput,
  ListButton,
  BlockFooter,
  Block,
  Navbar,
  BlockTitle,
  ListItem
} from "framework7-react";
import { connect } from "@cerebral/react";
import { sequences, state } from "cerebral";

export default connect(
  {
    companyName: state`config.companyName`,
    loggedIn: state`loggedIn`,
    foo: state`foo`,
    onClick: sequences`onClick`,
    signIn: sequences`app.signIn`,
    sendBroadcast: sequences`marketting.sendBroadcast`,
    getUsers: sequences`marketting.getUsers`,
    selectUser: sequences`marketting.selectUser`,
    users: state`users`,
    openUserContext: sequences`users.openUserContext`
  },
  class MyComponent extends React.Component {
    constructor() {
      super();
      this.state = {
        message: ""
      };
    }
    componentDidMount() {
      this.props.getUsers();
    }
    sendMessage() {
      if (this.state.message) {
        this.props.sendBroadcast({ message: this.state.message });
      }
    }
    onInput(e) {
      console.log(e.target.value);
      console.log(this.state.message);
      this.setState({ message: e.target.value });
    }
    render() {
      return (
        <Page>
          <Navbar title="Users" />
          <List mediaList>
            {this.props.users.map(user => {
              const {
                id,
                phone_number,
                user_id,
                created_at,
                display_name,
                banned
              } = user;

              return (
                <ListItem
                  onClick={() => {
                    this.props.openUserContext({ user });
                  }}
                  bgColor={banned ? "red" : ""}
                  title={phone_number}
                  subtitle={user_id}
                  link="#"
                />
              );
            })}
          </List>
        </Page>
      );
    }
  }
);
