import React from "react";
import {
  Page,
  LoginScreenTitle,
  List,
  ListInput,
  ListButton,
  BlockFooter,
  Block,
  Navbar
} from "framework7-react";
import { connect } from "@cerebral/react";
import { sequences, state } from "cerebral";

export default connect(
  {
    companyName: state`config.companyName`,
    loggedIn: state`loggedIn`,
    foo: state`foo`,
    onClick: sequences`onClick`,
    signIn: sequences`app.signIn`
  },
  class MyComponent extends React.Component {
    constructor() {
      super();
      this.state = {
        username: "",
        password: "",
        pigeons: []
      };
    }
    render() {
      const { companyName } = this.props;
      return (
        <Page>
          <Navbar title="Welcome White Label Customer" />
          <Block>
            <p>Manage your customer</p>
            <p>To log out just close to tab</p>
          </Block>
        </Page>
      );
    }
  }
);
