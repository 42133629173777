import { toastFactory } from "./factories";

export function setToken({ props, http }) {
  http.setToken(props.token);
}

export function signInServer({ path, props, http, config }) {
  return new Promise(resolve => {
    const { password, username } = props;
    if (!password || !username) {
      return resolve(path.error({ msg: "Password and/or Username is empty" }));
    }
    return http
      .post(config.api.signIn, {
        password,
        username
      })
      .then(response => {
        try {
          const { token } = response.data;
          if (!token) {
            return resolve(path.error({ error: "!token" }));
          }
          return resolve(path.success({ token }));
        } catch (error) {
          return resolve(path.error({ error }));
        }
      })
      .catch(error => {
        return resolve(path.error({ error }));
      });
  });
}

export const tryAgainToast = toastFactory("Try again later", "center");

export const successToast = toastFactory("Success!", "center");

export function clearButton() {
  try {
    document.getElementsByClassName("input-clear-button")[0].click();
  } catch (e) {}
}
