import app from "./modules/app";
import users from "./modules/users";
import marketting from "./modules/marketting";
import Config, { config } from "../config";
import { http } from "./providers/http";
import nav from "./providers/nav";

export default controller => {
  controller.app.on("initialized", () => {
    // for development
    if (localStorage.tenant && localStorage.token) {
      controller.app.getSequence("app.signInDev")({
        token: localStorage.token,
        username: localStorage.username
      });
    }
  });
  return {
    state: {
      users: [],
      selectedUsers: {},
      config,
      loggedIn: false
    },
    modules: {
      app,
      marketting,
      users
    },
    providers: {
      http,
      config: Config,
      nav
    }
  };
};
