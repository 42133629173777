import LoginPage from "./ui/LoginPage";
import WelcomePage from "./ui/WelcomePage";
import BroadcastPage from "./ui/BroadcastPage";
import LeftMenu from "./ui/LeftMenu";
import UsersPage from "./ui/UsersPage";
import RightUsersPanel from "./ui/RightUsersPanel";

export default [
  {
    path: "/",
    component: LoginPage
  },
  {
    path: "/WelcomePage/",
    component: WelcomePage
  },
  {
    path: "/panel-left/",
    component: LeftMenu
  },
  {
    path: "/panel-right-users/",
    component: RightUsersPanel
  },
  {
    path: "/BroadcastPage/",
    component: BroadcastPage
  },
  {
    path: "/UsersPage/",
    component: UsersPage
  }
];
