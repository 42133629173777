let toastInstance;
export function toastFactory(message, position) {
  return function toastFactory({ props }) {
    if (toastInstance) {
      toastInstance.close();
      toastInstance = null;
    }
    if (!toastInstance) {
      toastInstance = f7.toast.create({
        text: props.toastMessage || message,
        position: position || "bottom",
        closeButton: true,
        // closeButtonText: "Close",
        closeTimeout: 2000,
        // closeButtonColor: "red",
        on: {
          close() {
            toastInstance = null;
          }
        }
      });
    }
    toastInstance.open();
  };
}

export function navFactory({ page, reloadAll, animate }) {
  return function navFactory({ state, nav }) {
    nav.open({ page, reloadAll, animate });
  };
}
