export default function(context) {
  return {
    open({ props, page, reloadAll, animate }) {
      f7.views.main.router.navigate("/" + page + "/", {
        animate: typeof animate === "undefined",
        reloadAll,
        props
      });
    },
    back() {
      f7.views.main.router.back();
    },
    backToRoot() {
      f7.views.main.router.back("/TabbedPage/", {
        animate: true,
        clearPreviousHistory: true,
        force: true
      });
    }
  };
}
