import Devtools from "cerebral/devtools";
const debugHost = localStorage.getItem("debugHost");
const debugReconnect = localStorage.getItem("debugReconnect");

export const devtools = debugHost
  ? Devtools({
      host: localStorage.getItem("debugHost"),
      reconnect: debugReconnect || false,
      warnStateProps: false,
      bigComponentsWarning: false
    })
  : undefined;

//
// import Devtools from "cerebral/devtools";
// const debugHost = true || localStorage.getItem("debugHost");
//
// export const devtools = debugHost
//   ? Devtools({
//     host: "192.168.50.71:8888" || localStorage.getItem("debugHost"),
//     reconnect: false
//   })
//   : undefined;
