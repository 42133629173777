import { Reaction, state } from "cerebral";

export const token = Reaction(
  {
    token: state.app.token
  },
  ({ token }) => {
    sessionStorage.setItem("token", token);
  }
);
export const username = Reaction(
  {
    username: state.app.username
  },
  ({ username }) => {
    sessionStorage.setItem("username", username);
  }
);

export const loggedIn = Reaction(
  {
    loggedIn: state.loggedIn
  },
  ({ loggedIn }) => {
    sessionStorage.setItem("loggedIn", loggedIn);
  }
);
