import { state, props } from "cerebral/tags";
import { equals, when, push, set, unset } from "cerebral/operators";

import { sendBroadcastServer, getUsersServer } from "./actions";
import { clearButton, successToast, tryAgainToast } from "../app/actions";

export const sendBroadcast = [
  sendBroadcastServer,
  {
    success: [
      successToast
      // clearButton
    ],
    error: [tryAgainToast]
  }
];

export const getUsers = [
  getUsersServer,
  {
    success: [set(state`users`, props`users`)],
    error: []
  }
];

export const selectUser = [
  set(state`selectedUsers.${props`user.phone_number`}`, props`user`)
];

export const clearUsers = [set(state`selectedUsers`, {})];

export const uploadPhoneNumbers = [];
