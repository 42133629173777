export function banUserAction({ http, props, config, path, state }) {
  return new Promise(resolve => {
    const { message } = props;
    return http
      .post(config.api.banUser, {
        phone_number: props.user.phone_number,
        ban: props.user.banned ? 0 : 1
      })
      .then(response => {
        return resolve(path.success());
      })
      .catch(error => {
        return resolve(path.error({ error }));
      });
  });
}

export function openUserContextAction({ path, state, props }) {
  return new Promise(resolve => {
    f7.actions
      .create({
        buttons: [
          {
            text: "Select",
            onClick: () => resolve(path.select({ selected: false }))
          },
          {
            text: props.user.banned ? "Unban" : "Ban",
            onClick: () => resolve(path.ban({ selected: false }))
          },
          {
            text: "Cancel",
            type: "cancel",
            color: "red",
            onClick: () => resolve(path.cancel())
          }
        ]
      })
      .open();
  });
}
