import * as sequences from "./sequences";

export const state = {
  token: ""
};

export default {
  sequences: {
    ...sequences
  }
};
