import React from "react";
import { App, Panel, View } from "framework7-react";
import { state, sequences } from "cerebral";
import { connect } from "@cerebral/react";
import routes from "./routes";

export default connect(
  {
    loggedIn: state`loggedIn`,
    setPage: sequences`app.setPage`,
    page: state`page`
  },
  class MyComponent extends React.Component {
    componentDidMount() {
      this.$f7ready(f7 => {
        window.f7 = f7;
      });
    }
    render() {
      console.log("first render");

      const { page, setPage, loggedIn } = this.props;

      const f7Params = {
        id: "io.framework7.testapp",
        root: "#app",
        theme: "aurora",
        routes,
        on: {
          pageInit: function(page) {
            console.log(page);
            setPage({ page: page.route.path });
          }
        },
        popup: {
          closeOnEscape: true
        },
        sheet: {
          closeOnEscape: true
        },
        popover: {
          closeOnEscape: true
        },
        actions: {
          closeOnEscape: true
        }
      };
      return (
        <App params={f7Params}>
          {loggedIn && (
            <Panel
              collapsedBreakpoint={1}
              visibleBreakpoint={1024}
              themeDark
              left
              cover
              reveal
            >
              <View url="/panel-left/" linksView=".view-main" />
            </Panel>
          )}
          <View
            url={page || "/"}
            main
            className="safe-areas"
            masterDetailBreakpoint={800}
          />
          {loggedIn && (
            <Panel
              collapsedBreakpoint={1}
              visibleBreakpoint={1024}
              themeDark
              right
              cover
              reveal
            >
              <View url="/panel-right-users/" linksView=".view-main" />
            </Panel>
          )}
        </App>
      );
    }
  }
);
