import axios from "axios";
import { config } from "../../config";

const baseURL = localStorage.apiBaseURL || config.api.baseURL;

let token;

function withDefaultHeaders(config = {}) {
  return {
    baseURL,
    ...config,
    headers: {
      ...(config.headers || {}),
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`
    }
  };
}

export const http = {
  setToken(newToken) {
    token = newToken;
  },
  request(config) {
    return axios(withDefaultHeaders(config));
  },
  get(url, config) {
    return axios.get(url, withDefaultHeaders(config));
  },
  post(url, data, config) {
    console.log(data);
    return axios.post(url, data, withDefaultHeaders(config));
  }
};
