import React from "react";
import { render } from "react-dom";
import Framework7 from "framework7/framework7.esm.bundle";
import Framework7React from "framework7-react";
import AppComponent from "./AppComponent.jsx";

import Cerebral from "cerebral";
import { Container } from "@cerebral/react";
import main from "./main/main";
// import { devtools } from "./devtools";
import * as Sentry from "@sentry/browser";

import { devtools } from "../../app/src/devtools";

import { config } from "./config";

Sentry.init({
  dsn: config.sentryDsn
});

const app = Cerebral(main, { devtools });

Framework7.use(Framework7React);

render(
  <Container app={app}>
    <AppComponent />
  </Container>,
  document.getElementById("app")
);
