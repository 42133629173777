import * as sequences from "./sequences";
import * as reactions from "./reactions";

export const state = {
  token: ""
};

export default {
  reactions,
  state,
  sequences: {
    ...sequences
  }
};
