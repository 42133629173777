import React from "react";
import {
  Page,
  BlockTitle,
  Block,
  List,
  ListItem,
  Link,
  Icon
} from "framework7-react";
import { connect } from "@cerebral/react";
import { sequences, state } from "cerebral";

export default connect(
  {
    foo: state`foo`,
    onClick: sequences`onClick`,
    page: state`page`
  },
  ({ page }) => {
    const activeLink = "active-link";
    return (
      <Page>
        <List>
          <ListItem
            animate={false}
            className={
              page === "/WelcomePage/" ||
              page === "/WelcomePage" ||
              page === "/"
                ? activeLink
                : ""
            }
            link="/WelcomePage/"
            title="Welcome"
          >
            <Icon slot="media" icon="demo-list-icon" />
          </ListItem>
        </List>
        <List>
          <ListItem
            animate={false}
            className={page === "/UsersPage/" ? activeLink : ""}
            link="/UsersPage/"
            title="Users"
          >
            <Icon slot="media" icon="demo-list-icon" />
          </ListItem>
        </List>
        <List>
          <ListItem
            animate={false}
            className={page === "/BroadcastPage/" ? activeLink : ""}
            link="/BroadcastPage/"
            title="Broadcast"
          >
            <Icon slot="media" icon="demo-list-icon" />
          </ListItem>
        </List>
        {/*<List>*/}
        {/*<ListItem*/}
        {/*animate={false}*/}
        {/*className={page === "/UsersPage/" ? activeLink : ""}*/}
        {/*link="/TargettedBroadcast/"*/}
        {/*title="Targetted Broadcast"*/}
        {/*>*/}
        {/*<Icon slot="media" icon="demo-list-icon" />*/}
        {/*</ListItem>*/}
        {/*</List>*/}
      </Page>
    );
  }
);
