import { sendBroadcastServer } from "../marketting/actions";
import { clearButton, successToast, tryAgainToast } from "../app/actions";
import { banUserAction, openUserContextAction } from "./actions";
import { set } from "cerebral/factories";
import { props, state } from "cerebral/tags";
import { getUsers } from "../marketting/sequences";

export const banUser = [
  sendBroadcastServer,
  {
    success: [successToast, clearButton],
    error: [tryAgainToast]
  }
];

export const openUserContext = [
  openUserContextAction,
  {
    select: [
      set(state`selectedUsers.${props`user.phone_number`}`, props`user`)
    ],
    ban: [
      banUserAction,
      {
        success: [...getUsers],
        error: []
      }
    ],
    cancel: []
  }
];
