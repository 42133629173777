export function sendBroadcastServer({ http, props, config, path, state }) {
  return new Promise(resolve => {
    const { message, title } = props;

    const selectedUsersObj = state.get("selectedUsers");
    const selectedUsersArray = Object.keys(selectedUsersObj).map(
      key => selectedUsersObj[key]
    );

    const fileName = state.get("marketting.uploadedPhoneNumbersFileName");
    const fileSize = state.get("marketting.uploadedPhoneNumbersFileSize");
    const fileExist = fileName && fileSize;

    if (fileExist) {
      const data = new FormData();

      const files = document.getElementById("fileElem").files;
      const fileBlob = files[0];

      data.append("file", fileBlob);
      data.append("message", message);
      data.append("title", title);
      return http
        .post(config.api.targettedBroadcastWithFile, data, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          return resolve(path.success());
        })
        .catch(error => {
          return resolve(path.error({ error }));
        });
    }

    if (selectedUsersArray.length >= 1) {
      return http
        .post(config.api.targettedBroadcast, {
          message,
          title,
          users: selectedUsersArray
        })
        .then(response => {
          return resolve(path.success());
        })
        .catch(error => {
          return resolve(path.error({ error }));
        });
    } else {
      return http
        .post(config.api.sendBroadcast, {
          message,
          title
        })
        .then(response => {
          return resolve(path.success());
        })
        .catch(error => {
          return resolve(path.error({ error }));
        });
    }
  });
}

export function getUsersServer({ http, props, config, path, state }) {
  return new Promise(resolve => {
    const { message } = props;
    return http
      .post(config.api.getUsers, {})
      .then(response => {
        console.log(response.data.users);

        return resolve(path.success({ users: response.data.users || [] }));
      })
      .catch(error => {
        return resolve(path.error({ error }));
      });
  });
}
