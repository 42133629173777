import React from "react";
import {
  Page,
  List,
  ListInput,
  ListButton,
  Block,
  Navbar,
  NavRight,
  Link
} from "framework7-react";
import { connect } from "@cerebral/react";
import { sequences, state } from "cerebral";

const sample = require("../sample-numbers.txt");

export default connect(
  {
    companyName: state`config.companyName`,
    loggedIn: state`loggedIn`,
    foo: state`foo`,
    onClick: sequences`onClick`,
    signIn: sequences`app.signIn`,
    sendBroadcast: sequences`marketting.sendBroadcast`,
    uploadPhoneNumbers: sequences`marketting.uploadPhoneNumbers`,
    handleFile: sequences`app.handleFile`,
    uploadedPhoneNumbersFileName: state`marketting.uploadedPhoneNumbersFileName`,
    uploadedPhoneNumbersFileSize: state`marketting.uploadedPhoneNumbersFileSize`,
    clearFile: sequences`app.clearFile`
  },
  class MyComponent extends React.Component {
    constructor() {
      super();
      this.state = {
        title: "",
        message: ""
      };
    }
    sendMessage() {
      if (this.state.message) {
        this.props.sendBroadcast({
          message: this.state.message,
          title: this.state.title.trimLeft().trimRight()
        });
      }
    }
    uploadPhoneNumbers() {
      const fileElem = document.getElementById("fileElem");

      const handleFile = () => {
        const files = document.getElementById("fileElem").files;
        this.props.handleFile({ files });
        fileElem.removeEventListener("change", handleFile);
      };

      fileElem.addEventListener("change", handleFile, false);

      fileElem.click();
    }
    onInputTitle(e) {
      this.setState({ title: e.target.value });
    }
    onInput(e) {
      this.setState({ message: e.target.value });
    }
    render() {
      return (
        <Page>
          <Navbar title="Broadcast">
            <NavRight>
              <Link
                onClick={() => {
                  open(sample);
                }}
              >
                Sample Upload File
              </Link>
            </NavRight>
          </Navbar>
          <Block>
            <p>Broadcast a message to you subscriber base</p>
            <p>
              If selected users on the right panel is empty you will broadcast
              all the subscriber based.
            </p>
          </Block>
          <List>
            <ListInput
              onInput={this.onInputTitle.bind(this)}
              placeholder="Title (If empty 'Notification')"
              clearButton
            />
            <ListInput
              onInput={this.onInput.bind(this)}
              type="textarea"
              placeholder="Your message"
              clearButton
            />
            {this.state.message.trim() && (
              <ListButton onClick={this.sendMessage.bind(this)} title="Send" />
            )}
            <ListButton
              onClick={this.uploadPhoneNumbers.bind(this)}
              title={
                this.props.uploadedPhoneNumbersFileName &&
                this.props.uploadedPhoneNumbersFileSize
                  ? `Sending to this ${
                      this.props.uploadedPhoneNumbersFileName
                    } ${this.props.uploadedPhoneNumbersFileSize} bytes`
                  : "Upload Phone Number"
              }
            />
            <ListButton
              onClick={() => this.props.clearFile()}
              title="Clear File"
              style={{
                display:
                  this.props.uploadedPhoneNumbersFileName &&
                  this.props.uploadedPhoneNumbersFileSize
                    ? ""
                    : "none"
              }}
            />
            {/* hidden input element */}
            <form id="fileUploadForm" encType="multipart/form-data">
              <input
                type="file"
                id="fileElem"
                accept="text/*"
                style={{ display: "none" }}
              />
            </form>
          </List>
        </Page>
      );
    }
  }
);
