import { state, props } from "cerebral/tags";
import { equals, wait, when, set, unset } from "cerebral/operators";
import { tryAgainToast, signInServer, setToken } from "./actions";
import { navFactory, toastFactory } from "./factories";

export const nav = [
  ({ nav, props }) => {
    nav.open({ props, page: props.page });
  }
];

const signInCommon = [
  set(state`app.token`, props`token`),
  set(state`app.username`, props`username`),
  setToken,
  toastFactory("Logged in success", "center"),
  set(state`page`, "/WelcomePage/"),
  set(state`loggedIn`, true)
];

export const signIn = [
  signInServer,
  {
    success: [...signInCommon],
    error: [tryAgainToast]
  }
];

export const signInDev = [wait(1000), ...signInCommon];

export const setPage = [set(state`page`, props`page`)];

export const handleFile = [
  function({ state, props }) {
    if (
      props.files &&
      props.files[0] &&
      props.files[0].name &&
      props.files[0].size
    ) {
      const { name, size } = props.files[0];
      state.set("marketting.uploadedPhoneNumbersFileName", name);
      state.set("marketting.uploadedPhoneNumbersFileSize", size);
    }
  }
];

export const clearFile = [
  set(state`marketting.uploadedPhoneNumbersFileName`, ""),
  set(state`marketting.uploadedPhoneNumbersFileSize`, 0)
];
