export const config = {
  companyName: "Kosmos",
  sentryDsn:
    "https://46a1e1d584044dc292182ba063c6dff8@o66872.ingest.sentry.io/142570",
  api: {
    baseURL: "https://kosmos-lb.kosmosmobile.com/admin-api",
    signIn: "/signIn",
    sendBroadcast: "/sendBroadcast",
    targettedBroadcast: "/targettedBroadcast",
    targettedBroadcastWithFile: "/targettedBroadcastWithFile",
    getUsers: "/getUsers",
    banUser: "/banUser"
  }
};

export default function() {
  return {
    ...config
  };
}
